<template>
  <div style="height: 100%" class="home-container">
    <v-app-bar fixed app class="title-bar">
      <Logo @click="requestsNav" />
      <v-list class="nav-bar">
        <v-list-item
          v-for="item in sidebar_items"
          :key="item.text"
          @click.stop="item.method()"
          ripple
          class="list-item"
          active-class="active"
          :to="item.text != 'Messages' ? item.path : messagesPath"
          v-show="item.show"
        >
          <template v-if="item.text != 'Messages'">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-else>
            <v-badge
              inline
              right
              color="secondary"
              :content="hasUnreadMessages"
              :value="showMessageBadge"
              class="badge-wrapper"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 20px">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-badge>
          </template>
        </v-list-item>
      </v-list>
      <v-app-bar-nav-icon
        class="nav-bar-icon"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer right v-model="drawer" temporary app>
      <v-list>
        <v-list-item
          v-for="item in sidebar_items"
          :key="item.text"
          @click.stop="item.method()"
          ripple
          active-class="active"
          class="list-item"
          :to="item.text != 'Messages' ? item.path : messagesPath"
          v-show="item.show"
        >
          <template v-if="item.text != 'Messages'">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-else>
            <v-badge
              inline
              right
              color="secondary"
              :content="hasUnreadMessages"
              :value="showMessageBadge"
              class="badge-wrapper"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 20px">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-badge>
          </template>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: 100%; padding-top: 110px">
      <div class="content-container">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import { SITE, SHOW_CHATS } from "@/config";
import { Logout } from "@/api/auth/logout";
import { InitWebsocket } from "@/api/messages/ws";
import { GetChats } from "@/api/messages/get_chats.js";
import Logo from "@/components/logo.vue";

export default {
  name: "Home",
  components: { Logo },

  data() {
    return {
      socket: null,
      dialog: false,
      drawer: null,
      sidebar_items: [
        {
          icon: "assignment_returned",
          text: "Responses",
          path: "/home/requests",
          method: this.nothing,
          show: true
        },
        {
          icon: "question_answer",
          text: "Messages",
          path: "",
          method: this.nothing,
          show: SHOW_CHATS
        },
        {
          icon: "person",
          text: "Profile",
          path: "/home/profile",
          method: this.nothing,
          show: true
        },
        {
          icon: "lock",
          text: "Log Out",
          path: "",
          method: this.logOut,
          show: true
        }
      ]
    };
  },
  created() {
    const token = this.$store.getters.token;
    if (token == null) {
      this.$store.commit("set_token", localStorage.getItem("token"));
    }
    const self = this.$store.getters.self;
    if (self == null) {
      this.$store.commit("set_self", localStorage.getItem("self"));
    }
    const last_seen = this.$store.getters.last_seen;
    if (last_seen == null) {
      this.$store.commit("set_last_seen", localStorage.getItem("last_seen"));
    }
  },
  async beforeMount() {
    try {
      await GetChats();
      this.socket = InitWebsocket();
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    siteName() {
      return SITE.toLowerCase();
    },
    hasUnreadMessages() {
      return this.$store.getters.hasUnreadMessages;
    },
    showMessageBadge() {
      return this.$store.getters.hasUnreadMessages > 0;
    },
    messagesPath() {
      let chatID = this.$store.getters.mostRecentChatID;

      if (chatID === "") {
        chatID = "empty";
      }
      return `/home/chat/${chatID}`;
    }
  },
  methods: {
    requestsNav() {
      this.$router.push({
        name: "requests"
      });
    },

    async logOut() {
      await Logout();
    },
    nothing() {
      return;
    }
  }
};
</script>

<style scoped>
::v-deep .v-app-bar {
  background-color: #fff !important;
  box-shadow: none !important;
  height: 110px !important;
  display: flex;
  justify-content: center;
}
::v-deep .title-bar .v-toolbar__content {
  height: 110px !important;
  width: 1200px;
  justify-content: space-between !important;
}

.content-container {
  width: 95%;
  margin: 15px auto;
}

.title-bar {
  margin-bottom: 30px;
}
.nav-bar {
  display: flex;
}
::v-deep .v-list-item__action {
  margin-right: 8px !important;
}
::v-deep .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-grey-base) !important;
}
.list-item {
  padding: 8px 16px;
}
.active {
  color: var(--v-primary-base) !important;
}
.nav-bar-icon {
  display: none;
  padding: 0 32px;
}

@media only screen and (max-width: 1279px) {
  .nav-bar {
    display: none;
  }
  .nav-bar-icon {
    display: block;
  }
  ::v-deep .title-bar .v-toolbar__content {
    justify-content: space-between !important;
    padding: 0 50px;
  }
}

@media only screen and (max-width: 360px) {
  .content-container {
    width: 100%;
    margin: 0;
  }
  .title-bar {
    margin-bottom: 30px;
    padding-left: 0;
  }
}
</style>
