import Vue from "vue";
import colors from "vuetify/lib/util/colors";
import { WS_BASE_URL, WS_SERVICE_PORT } from "@/config";
import store from "@/store";
import EventBus from "@/event-bus";

export const InitWebsocket = async () => {
  let socket = new WebSocket(`${WS_BASE_URL}${WS_SERVICE_PORT}/v1/ws`);

  socket.onopen = function() {
    socket.send(
      JSON.stringify({
        created_at: Date.now(),
        type: "authorization",
        token: store.getters.token
      })
    );
    Vue.prototype.$socket = socket;
  };

  socket.onmessage = function(event) {
    let msg = JSON.parse(event.data);
    let { id, company_name } = store.getters.message_sender(
      msg.chat_id,
      msg.sender_id
    );

    if (id !== store.getters.self) {
      EventBus.$emit("toast", {
        text: `New Message from ${company_name}`,
        type: colors.grey.darken4,
        im: true,
        snack_link: `/home/chat/${msg.chat_id}`
      });
    }
    store.commit("add_message", { chat_id: msg.chat_id, msg: msg });
    store.commit("set_chat_last_message", { chat_id: msg.chat_id, msg: msg });
  };

  socket.onerror = function(error) {
    console.error(`[error] ${error.message}`);
  };
  return socket;
};
